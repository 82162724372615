<template>
  <v-content>
    <section id="hero">
      <v-row no-gutters>
        <v-img
          :min-height="'calc(100vh - ' + $vuetify.application.top + 'px)'"
          :src="require('../assets/header.png')"
        >
          <v-theme-provider dark>
            <v-container fill-height>
              <v-row
                align="center"
                class="white--text mx-auto"
                justify="center"
              >
                <v-col class="white--text text-center" cols="12" tag="h1">
                  <span
                    :class="[
                      $vuetify.breakpoint.smAndDown ? 'display-1' : 'display-2',
                    ]"
                    class="font-weight-light"
                  >
                  </span>

                  <br />

                  <span
                    :class="[
                      $vuetify.breakpoint.smAndDown ? 'display-3' : 'display-4',
                    ]"
                    class="font-weight-black"
                  >
                  </span>
                </v-col>

                <v-btn
                  class="align-self-end"
                  fab
                  outlined
                  @click="$vuetify.goTo('#about-me')">
                  <v-icon>mdi-chevron-double-down</v-icon>
                </v-btn>
              </v-row>
            </v-container>
          </v-theme-provider>
        </v-img>
      </v-row>
    </section>

    <section id="about-me">
      <div class="py-12"></div>

      <v-container class="text-center">
        <h2 class="display-2 font-weight-bold mb-3">ABOUT US</h2>

        <v-responsive class="mx-auto mb-8" width="56">
          <v-divider class="mb-1"></v-divider>

          <v-divider></v-divider>
        </v-responsive>

        <v-responsive
          class="mx-auto title font-weight-light mb-8"
          max-width="720"
        >
          Quality Matters. Production and distribution of Padel equipment from Spain.
        </v-responsive>

        <v-avatar
            class="elevation-10 mb-12 mx-6"
            size="140"
          >
          <v-img :src="require('../assets/nb-logo2.png')"></v-img>
        </v-avatar>

        <v-avatar
            class="elevation-10 mb-12 mx-6"
            size="140"
          >
          <v-img :src="require('../assets/vibora-logo2.png')"></v-img>
        </v-avatar>

        <v-avatar
            class="elevation-10 mb-12 mx-6"
            size="140"
          >
          <v-img :src="require('../assets/softee-logo2.png')"></v-img>
        </v-avatar>

        <v-avatar
            class="elevation-10 mb-12 mx-6"
            size="140"
          >
          <v-img :src="require('../assets/ocho-logo2.png')"></v-img>
        </v-avatar>

        <v-responsive
          class="mx-auto title font-weight-light mb-8"
          max-width="720"
        >
          Official distributor for VIBOR-A, ENEBE, SOFTEE & OCHO PADEL in GCC Countries. 
          Based in United Arab Emirates, we offer our products to Bahrain, Kuwait, Oman, Qatar, Saudi Arabia, and UAE.
        </v-responsive>

      </v-container>

      <div class="py-12"></div>
    </section>



    <v-sheet id="contact" color="#333333" dark tag="section" tile>
      <div class="py-12"></div>

      <v-container>
        <h2 class="display-2 font-weight-bold mb-3 text-uppercase text-center">
          Contact
        </h2>

        <v-responsive class="mx-auto mb-12" width="56">
          <v-divider class="mb-1"></v-divider>

          <v-divider></v-divider>
        </v-responsive>

        <v-theme-provider light>
          <v-row>
            <v-col cols="12" sm="4">
              <v-icon>mdi-email</v-icon> sales@globalsportsme.com
            </v-col>

            <v-col cols="12" sm="4">
              <v-icon>mdi-phone</v-icon> +971 (0) 58 520 98 58
            </v-col>

            <v-col cols="12" sm="4">
              <v-icon>mdi-phone</v-icon> +971 (0) 58 537 10 71
            </v-col>

            <iframe class="py-10 d-flex justify-center" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d28908.392773000483!2d55.122060533496274!3d25.083267522739224!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f6b5402c126e3%3A0xb9511e6655c46d7c!2sDubai%20Marina%20-%20Dubai!5e0!3m2!1sen!2sae!4v1642697151664!5m2!1sen!2sae" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy"></iframe>
          </v-row>
        </v-theme-provider>
      </v-container>

      <div class="py-12"></div>
    </v-sheet>
  </v-content>
</template>

<script>
export default {
  name: "HelloWorld",

  data: () => ({
    
  }),
};
</script>
